// import { useEffect } from "react";

const availableCounty = [
    "middlesex south", 
]

const cityCountyLookup_MA = {
    "abington": "plymouth",
    "acton": "middlesex south",
    "acushnet": "bristol south",
    "adams": "berkshire north",
    "agawam": "hampden",
    "alford": "berkshire south",
    "amesbury": "essex south",
    "amherst": "hampshire",
    "andover": "essex north",
    "aquinnah": "dukes",
    "arlington": "middlesex south",
    "ashburnham": "worcester north",
    "ashby": "middlesex south",
    "ashfield": "franklin",
    "ashland": "middlesex south",
    "athol": "worcester",
    "attleboro": "bristol north",
    "auburn": "worcester",
    "avon": "norfolk",
    "ayer": "middlesex south",
    "barnstable": "barnstable",
    "barre": "worcester",
    "becket": "berkshire middle",
    "bedford": "middlesex south",
    "belchertown": "hampshire",
    "bellingham": "norfolk",
    "belmont": "middlesex south",
    "berkley": "bristol north",
    "berlin": "worcester",
    "bernardston": "franklin",
    "beverly": "essex south",
    "billerica": "middlesex north",
    "blackstone": "worcester",
    "blandford": "hampden",
    "bolton": "worcester",
    "boston": "suffolk",
    "bourne": "barnstable",
    "boxborough": "middlesex south",
    "boxford": "essex south",
    "boylston": "worcester",
    "braintree": "norfolk",
    "brewster": "barnstable",
    "bridgewater": "plymouth",
    "brimfield": "hampden",
    "brockton": "plymouth",
    "brookfield": "worcester",
    "brookline": "norfolk",
    "buckland": "franklin",
    "burlington": "middlesex south",
    "cambridge": "middlesex south",
    "canton": "norfolk",
    "carlisle": "middlesex north",
    "carver": "plymouth",
    "charlemont": "franklin",
    "charlton": "worcester",
    "chatham": "barnstable",
    "chelmsford": "middlesex north",
    "chelsea": "suffolk",
    "cheshire": "berkshire north",
    "chester": "hampden",
    "chesterfield": "hampshire",
    "chicopee": "hampden",
    "chilmark": "dukes",
    "clarksburg": "berkshire north",
    "clinton": "worcester",
    "cohasset": "norfolk",
    "colrain": "franklin",
    "concord": "middlesex south",
    "conway": "franklin",
    "cummington": "hampshire",
    "dalton": "berkshire middle",
    "danvers": "essex south",
    "dartmouth": "bristol south",
    "dedham": "norfolk",
    "deerfield": "franklin",
    "dennis": "barnstable",
    "dighton": "bristol north",
    "dorchester": "suffolk",
    "douglas": "worcester",
    "dover": "norfolk",
    "dracut": "middlesex north",
    "dudley": "worcester",
    "dunstable": "middlesex north",
    "duxbury": "plymouth",
    "east bridgewater": "plymouth",
    "east brookfield": "worcester",
    "east longmeadow": "hampden",
    "eastham": "barnstable",
    "easthampton": "hampshire",
    "easton": "bristol north",
    "edgartown": "dukes",
    "egremont": "berkshire south",
    "erving": "franklin",
    "essex": "essex south",
    "everett": "middlesex south",
    "fairhaven": "bristol south",
    "fall river": "bristol fall river",
    "falmouth": "barnstable",
    "fitchburg": "worcester north",
    "florida": "berkshire north",
    "foxborough": "norfolk",
    "framingham": "middlesex south",
    "franklin": "norfolk",
    "freetown": "bristol fall river",
    "gardner": "worcester",
    "georgetown": "essex south",
    "gill": "franklin",
    "gloucester": "essex south",
    "goshen": "hampshire",
    "gosnold": "dukes",
    "grafton": "worcester",
    "granby": "hampshire",
    "granville": "hampden",
    "great barrington": "berkshire south",
    "greenfield": "franklin",
    "groton": "middlesex south",
    "groveland": "essex south",
    "hadley": "hampshire",
    "halifax": "plymouth",
    "hamilton": "essex south",
    "hampden": "hampden",
    "hancock": "berkshire north",
    "hanover": "plymouth",
    "hanson": "plymouth",
    "hardwick": "worcester",
    "harvard": "worcester",
    "harwich": "barnstable",
    "hatfield": "hampshire",
    "haverhill": "essex south",
    "hawley": "franklin",
    "heath": "franklin",
    "hingham": "plymouth",
    "hinsdale": "berkshire middle",
    "holbrook": "norfolk",
    "holden": "worcester",
    "holland": "hampden",
    "holliston": "middlesex south",
    "holyoke": "hampden",
    "hopedale": "worcester",
    "hopkinton": "middlesex south",
    "hubbardston": "worcester",
    "hudson": "middlesex south",
    "hull": "plymouth",
    "huntington": "hampshire",
    "ipswich": "essex south",
    "kingston": "plymouth",
    "lakeville": "plymouth",
    "lancaster": "worcester",
    "lanesborough": "berkshire north",
    "lawrence": "essex north",
    "lee": "berkshire middle",
    "leicester": "worcester",
    "lenox": "berkshire middle",
    "leominster": "worcester north",
    "leverett": "franklin",
    "lexington": "middlesex south",
    "leyden": "franklin",
    "lincoln": "middlesex south",
    "littleton": "middlesex south",
    "longmeadow": "hampden",
    "lowell": "middlesex north",
    "ludlow": "hampden",
    "lunenburg": "worcester north",
    "lynn": "essex south",
    "lynnfield": "essex south",
    "malden": "middlesex south",
    "manchester": "essex south",
    "mansfield": "bristol north",
    "marblehead": "essex south",
    "marion": "plymouth",
    "marlborough": "middlesex south",
    "marshfield": "plymouth",
    "mashpee": "barnstable",
    "mattapoisett": "plymouth",
    "maynard": "middlesex south",
    "medfield": "norfolk",
    "medford": "middlesex south",
    "medway": "norfolk",
    "melrose": "middlesex south",
    "mendon": "worcester",
    "merrimac": "essex south",
    "methuen": "essex north",
    "middleborough": "plymouth",
    "middlefield": "hampshire",
    "middleton": "essex south",
    "milford": "worcester",
    "millbury": "worcester",
    "millis": "norfolk",
    "millville": "worcester",
    "milton": "norfolk",
    "monroe": "franklin",
    "monson": "hampden",
    "montague": "franklin",
    "monterey": "berkshire south",
    "montgomery": "hampden",
    "mt washington": "berkshire south",
    "nahant": "essex south",
    "nantucket": "nantucket",
    "natick": "middlesex south",
    "needham": "norfolk",
    "new ashford": "berkshire north",
    "new bedford": "bristol south",
    "new braintree": "worcester",
    "new marlborough": "berkshire south",
    "new salem": "franklin",
    "newbury": "essex south",
    "newburyport": "essex south",
    "newton": "middlesex south",
    "norfolk": "norfolk",
    "north adams": "berkshire north",
    "north andover": "essex north",
    "north attleborough": "bristol north",
    "north brookfield": "worcester",
    "north reading": "middlesex south",
    "northampton": "hampshire",
    "northborough": "worcester",
    "northbridge": "worcester",
    "northfield": "franklin",
    "norton": "bristol north",
    "norwell": "plymouth",
    "norwood": "norfolk",
    "oak bluffs": "dukes",
    "oakham": "worcester",
    "orange": "franklin",
    "orleans": "barnstable",
    "otis": "berkshire middle",
    "oxford": "worcester",
    "palmer": "hampden",
    "paxton": "worcester",
    "peabody": "essex south",
    "pelham": "hampshire",
    "pembroke": "plymouth",
    "pepperell": "middlesex south",
    "peru": "berkshire middle",
    "petersham": "worcester",
    "phillipston": "worcester",
    "pittsfield": "berkshire middle",
    "plainfield": "hampshire",
    "plainville": "norfolk",
    "plymouth": "plymouth",
    "plympton": "plymouth",
    "princeton": "worcester",
    "provincetown": "barnstable",
    "quincy": "norfolk",
    "randolph": "norfolk",
    "raynham": "bristol north",
    "reading": "middlesex south",
    "rehoboth": "bristol north",
    "revere": "suffolk",
    "richmond": "berkshire middle",
    "rochester": "plymouth",
    "rockland": "plymouth",
    "rockport": "essex south",
    "rowe": "franklin",
    "rowley": "essex south",
    "royalston": "worcester",
    "russell": "hampden",
    "rutland": "worcester",
    "salem": "essex south",
    "salisbury": "essex south",
    "sandisfield": "berkshire south",
    "sandwich": "barnstable",
    "saugus": "essex south",
    "savoy": "berkshire north",
    "scituate": "plymouth",
    "seekonk": "bristol north",
    "sharon": "norfolk",
    "sheffield": "berkshire south",
    "shelburne": "franklin",
    "sherborn": "middlesex south",
    "shirley": "middlesex south",
    "shrewsbury": "worcester",
    "shutesbury": "franklin",
    "somerset": "bristol fall river",
    "somerville": "middlesex south",
    "south hadley": "hampshire",
    "southampton": "hampshire",
    "southborough": "worcester",
    "southbridge": "worcester",
    "southwick": "hampden",
    "spencer": "worcester",
    "springfield": "hampden",
    "sterling": "worcester",
    "stockbridge": "berkshire middle",
    "stoneham": "middlesex south",
    "stoughton": "norfolk",
    "stow": "middlesex south",
    "sturbridge": "worcester",
    "sudbury": "middlesex south",
    "sunderland": "franklin",
    "sutton": "worcester",
    "swampscott": "essex south",
    "swansea": "bristol fall river",
    "taunton": "bristol north",
    "templeton": "worcester",
    "tewksbury": "middlesex north",
    "tisbury": "dukes",
    "tolland": "hampden",
    "topsfield": "essex south",
    "townsend": "middlesex south",
    "truro": "barnstable",
    "tyngsborough": "middlesex north",
    "tyringham": "berkshire middle",
    "upton": "worcester",
    "uxbridge": "worcester",
    "wakefield": "middlesex south",
    "wales": "hampden",
    "walpole": "norfolk",
    "waltham": "middlesex south",
    "ware": "hampshire",
    "wareham": "plymouth",
    "warren": "worcester",
    "warwick": "franklin",
    "washington": "berkshire middle",
    "watertown": "middlesex south",
    "wayland": "middlesex south",
    "webster": "worcester",
    "wellesley": "norfolk",
    "wellfleet": "barnstable",
    "wendell": "franklin",
    "wenham": "essex south",
    "west boylston": "worcester",
    "west bridgewater": "plymouth",
    "west brookfield": "worcester",
    "west newbury": "essex south",
    "west springfield": "hampden",
    "west stockbridge": "berkshire south",
    "west tisbury": "dukes",
    "westborough": "worcester",
    "westfield": "hampden",
    "westford": "middlesex north",
    "westhampton": "hampshire",
    "westminster": "worcester north",
    "weston": "middlesex south",
    "westport": "bristol south",
    "westwood": "norfolk",
    "weymouth": "norfolk",
    "whately": "franklin",
    "whitman": "plymouth",
    "wilbraham": "hampden",
    "williamsburg": "hampshire",
    "williamstown": "berkshire north",
    "wilmington": "middlesex north",
    "winchendon": "worcester",
    "winchester": "middlesex south",
    "windsor": "berkshire north",
    "winthrop": "suffolk",
    "woburn": "middlesex south",
    "worcester": "worcester",
    "worthington": "hampshire",
    "wrentham": "norfolk",
    "yarmouth": "barnstable"
}

export default function isV2Avail ({ addressInfo }) {
    // console.log("====check if Accretion service is available==="); 
    // console.log("addressInfo=", addressInfo);

    if (!addressInfo) {
        return false;
    }

    if (addressInfo.state.toLowerCase() !== "massachusetts") {
        return false;
    }
    const city = addressInfo.locality.toLowerCase(); 
    const county = cityCountyLookup_MA[city]; 
    // console.log("the city ", city, " is in the ", county, "county");

    if (!county) {
        return false; 
    }
    
    const v2Available = availableCounty.includes(county);
    // console.log("v2 available is", v2Available);

    return v2Available;
}