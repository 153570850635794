
export default function createQueryString_title_report({addressInfo, propertyID}) {        
    
    if (!addressInfo) {
        console.error("createQueryString_title_report: addressInfo is null");
        return null;
    }

    if (!propertyID) {
        console.error("createQueryString_title_report: propertyID is null");
        return null;
    }

    // create encoded query string 
    const encodedQueryString =  `?propertyID=${propertyID}
                                 &addressInfo=${encodeURIComponent(JSON.stringify(addressInfo))}`;
    
    return (encodedQueryString)         
}