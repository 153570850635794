import LoadingContent from '../animation/LoadingContent';
import '../TitleReport.css';

export default function ScheduleA ({scheduleA}) {
            
    if (!scheduleA) {
        return (
            <div className='schedule-a'>
                <div className='row'> 
                    <div className='col-key'>Property Type:  </div>
                    <div className='col-val'> <LoadingContent/>  </div>                
                </div>

                <div className='row'> 
                    <div className='col-key'>Owner Vesting:  </div>
                    <div className='col-val'><LoadingContent/>  </div>                
                </div>

            </div>
        );
    }
    
    return (
        <div className='schedule-a'>
            <div className='row'> 
                <div className='col-key'>Property Type:  </div>
                <div className='col-val'> {scheduleA.property_type} </div>                
            </div>

            <div className='row'> 
                <div className='col-key'>Owner Vesting:  </div>
                <div className='col-val'> {scheduleA.owner_vesting}  </div>                
            </div>

        </div>
    )
}
