import React from 'react';
import { useEffect, useState } from 'react';

const urlBackendTitleChat= process.env.REACT_APP_BACKEND_DOMAIN + "/api/title-RAG/title-chat/"; 
const urlBackendChatHitory= process.env.REACT_APP_BACKEND_DOMAIN + "/api/title-RAG/chat-history/";

const ActionProvider = ({ createChatBotMessage, setState, children, propertyID }) => {      

  // Add a loading message function
  const createLoadingMessage = () => {
    return createChatBotMessage("Loading, please wait...");
  };


  const addMessageToState = (message, type) => {
    setState((prevState) => ({
      ...prevState,
      messages: [...prevState.messages, { type, message }],      
    }));
  };      
  
  const sendMessageToTitleChat = async (messageUser) => {
    const csrftoken = document.cookie.split('; ').find(row => row.startsWith('csrftoken=')).split('=')[1];
        
    const loadingMessage = createLoadingMessage();
    addMessageToState(loadingMessage.message, 'bot');
    
    try {
      const response = await fetch(urlBackendTitleChat + `?messageUser=${messageUser}&propertyID=${propertyID}`, {
        method: "POST",
        credentials: "include",
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': csrftoken,
        },
        body: JSON.stringify({
          "propertyID": propertyID,
          "messageUser": messageUser,
        })
      });

      if (response.ok) {
        const data = await response.json();                
        const messageAccretion = createChatBotMessage(data);
        addMessageToState(messageAccretion.message, 'bot');
      } else {
        const errorMessage = createChatBotMessage("Failed to fetch title report. Please try again.");        
        addMessageToState(errorMessage.message, 'bot');
      }
    } catch (error) {
      const errorMessage = createChatBotMessage("An error occurred while fetching the title report.");      
      addMessageToState(errorMessage.message, 'bot');
    } 
  };

  // fetch chat history 
  const fetchChatHistory = async () => {
    try {
      const response = await fetch(`${urlBackendChatHitory}?propertyID=${encodeURIComponent(propertyID)}`, {
        method: "GET",
        credentials: "include" // Include cookies if needed for authentication
      });

      if (response.ok) {
        const data = await response.json();        
        data.chat_history.forEach(chat => {
          addMessageToState(chat.message, 'user');
          addMessageToState(chat.response, 'bot');
        });
      } else {
        console.error("Failed to fetch chat history.");
      }
    } catch (error) {
      console.error("An error occurred while fetching chat history.");
    }
  }

  useEffect(() => {
    if (propertyID) {
      fetchChatHistory();
    }
  }, [propertyID]);

  const actions = {
    sendMessageToTitleChat, 
    fetchChatHistory,
  };

  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          actions: actions,          
        });
      })}
    </div>
  );
};

export default ActionProvider;