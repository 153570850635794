import { TypeAnimation } from 'react-type-animation';

const AnimationTypingAIForIndividual = () => {
  return (
    <TypeAnimation
      sequence={[
        'I want to invest in a second home',
        500, 
        'I want to sell my home',
        500,
        'I want to refinance my property',
        500,
        'Should I rent or buy?',
        500,
      ]}
      wrapper="span"
      speed={50}
      deletionSpeed={80}
      style={{ fontSize: '25px', display: 'inline-block', textAlign: 'left' }}
      repeat={Infinity}
    />
  );
};

export default AnimationTypingAIForIndividual;