import { Helmet } from 'react-helmet';
import { useState, } from 'react';

import { Text, Button, Divider, AbsoluteCenter, VStack, HStack, Icon } from '@chakra-ui/react';
import { Tabs, TabList, TabPanels, Tab, TabPanel, Box,  } from "@chakra-ui/react";

import { GrSchedulePlay, GrValidate } from "react-icons/gr";
import { RiLightbulbFlashLine } from "react-icons/ri";

import LearnMore from './v1/LearnMore';
import { ButtonTalkWithOurTeam } from '../components/buttons/ButtonsChakra';

import imageIntegration from './v1/image-integration.png';
import imageAutomation from './v1/image-automation.png'; 
import AnimationTypingSearch from './v1/AnimationTypingSearch';
import imageVisualization from '../ai/visualization-card.png';

import './Database.css';


const tabStyle = {
    _selected: { color: 'white', bg: 'gray.900' }, // Selected tab styles
    _hover: { color: 'white', bg: 'gray.700' }, // Hover effect
    color: 'black', // Default color for unselected tabs    
    margin: '0 20px 0 20px',
};

const boxStyle = {
    mW: '800px',
    mH: '60svh',
    margin: 'auto',
    mt: '50px',
};

const tabsStyle = {
    isFitted: true,
    variant: 'soft-rounded',
    // border: 'solid black',    
};


const PanelIntegration = () => {
    return (
        <div>
            <div className='tab-panel'>
                <div className='row'>
                    <div id='headline'>
                        Integrate documents into a knowledge base for AI automation.
                    </div>
                </div>
                <div className='row'>
                    <img src={imageIntegration} alt='image-integration'/>
                </div>
                <div className='row' style={{maxWidth:"350px"}}>
                    <div className='column'> 
                        <LearnMore/>
                    </div>
                    <div className='column'>
                        <ButtonTalkWithOurTeam/>
                    </div>
                </div>
            </div>
        </div>
    )
}

const PanelEfficiency = () => {
    const [hoveredTerm, setHoveredTerm] = useState('default');
    
    const ButtonStyleSeachTerm = {
        variant: "outline", 
        color: "black", 
        border: "solid black",
        _hover: { color: 'black', bg: 'gray.200' },
    }

    const ButtonSearchTerm = ({ searchTerm }) => {
        return (
            <Button 
                {...ButtonStyleSeachTerm} 
                onMouseEnter={ () => {setHoveredTerm(searchTerm); console.log("button search term, ", searchTerm)}}
            >
                {searchTerm}
            </Button>
        )
    }

    return (
        <div>
            <div className='tab-panel'>
                <Box position='relative' padding='10'>
                    <Divider />
                    <AbsoluteCenter bg='white' px='4'>
                        Search
                    </AbsoluteCenter>
                </Box>
                <div className='row'>
                    <div id='headline'>
                        Search a needle in a haystack through the entire database. 
                    </div>
                </div>

                <div className='row'>
                    
                    <div className='column' style={{flex:"3", minHeight:"200px", margin:"auto"}}>                        
                        <div className='row' style={{textAlign:"center", padding:"10px", width:"80%"}}>
                            <strong style={{fontSize:"25px"}}>
                                Simply use natural language:
                            </strong>
                        </div>
                        <div className='row' style={{textAlign:"center", padding:"10px", width:"80%"}}>
                            <AnimationTypingSearch searchTerm={ hoveredTerm }/>
                        </div> 
                    </div>

                    <div className='column' style={{flex:"1", fontSize:"18px"}}>
                        <div className='row' id='search-item'> 
                            <ButtonSearchTerm searchTerm="APN" />
                        </div>
                        <div className='row' id='search-item'> 
                            <ButtonSearchTerm searchTerm="Address" />
                        </div>
                        <div className='row' id='search-item'> 
                            <ButtonSearchTerm searchTerm="Name" />
                        </div>
                        <div className='row' id='search-item'> 
                            <ButtonSearchTerm searchTerm="Title number" />
                        </div>
                        <div className='row' id='search-item'> 
                            <ButtonSearchTerm searchTerm="Anything" />
                        </div>   
                    </div>
                </div>   

                <Box position='relative' padding='10'>
                    <Divider />
                    <AbsoluteCenter bg='white' px='4'>
                        Organization
                    </AbsoluteCenter>
                </Box>

                <div className='row'>
                    <div id='headline'>
                        Organize workload with a clean visual
                    </div>                    
                </div>  
                <div className='row' style={{width:"70%"}}>
                    <img src={imageVisualization} alt="image-visualization"/>
                </div>
                <div className='row' style={{maxWidth:"350px"}}>
                    <div className='column'>
                        <LearnMore />
                    </div>
                    <div className='column'>
                        <ButtonTalkWithOurTeam />
                    </div>
                </div>         
            </div>
        </div>
    )

}

const PanelAutomation = () => {
    const AutomationTerms = () => {        
        const textStyle = {
            fontSize: "18px", 
            textAlign: "left",
            padding: "2px",
        }
        const iconStyle = {
            boxSize: 8,
            margin: "5px 10px 0 0", 
        }
        return (    
            <VStack spacing={2} maxWidth="80%">            
                <HStack  align="flex-start" spacing={3}  w="100%">
                    <Icon as={GrSchedulePlay} {...iconStyle}/>
                    <Text {...textStyle} >
                        Periodically check for record updates on county websites.
                    </Text>            
                </HStack> 
                <HStack  align="flex-start" spacing={3}  w="100%">
                    <Icon as={GrValidate} {...iconStyle}/>
                    <Text {...textStyle} >
                        Validate critical terms, such as legal descriptions, in all documents.
                    </Text>            
                </HStack>  
                <HStack  align="flex-start" spacing={3}  w="100%">
                    <Icon as={RiLightbulbFlashLine} {...iconStyle}/>
                    <Text {...textStyle} >
                        Talk to our team today to unlock endless possibilities for automating your tedious workloads. 
                    </Text>            
                </HStack>            
            </VStack>    
        );
    }
    return (
        <div>
            <div className='tab-panel'>
                <div className='row'>
                    <div id='headline'>
                        Automate repetitive workloads using Accretion AI  
                    </div>
                </div> 
                <div className='row'>
                    <img src={imageAutomation} alt='image-automation'/>
                </div>
                <div className='row'>
                    <AutomationTerms/>
                </div>
                <div className='row' style={{maxWidth:"350px"}}>
                    <div className='column'>
                        <LearnMore/>
                    </div>
                    <div className='column'>
                        <ButtonTalkWithOurTeam/>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default function Database () {        

    const TabComponent = () => {
        return (
            <Box {...boxStyle}>
              <Tabs {...tabsStyle}>
                <TabList mb="1em">
                    <Tab {...tabStyle}>Integration</Tab>
                    <Tab {...tabStyle}>Efficiency</Tab>
                    <Tab {...tabStyle}>Automation</Tab>                                     
                </TabList>
                <TabPanels>
                    <TabPanel>
                        <PanelIntegration/>
                    </TabPanel>
                    <TabPanel>
                        <PanelEfficiency/>
                    </TabPanel>
                    <TabPanel>
                        <PanelAutomation/>
                    </TabPanel>                    
                </TabPanels>
              </Tabs>
            </Box>
          );
    }

    return (
        <div className='database'>
            <Helmet>
                <title> Database | Build AI Ready Title Databases </title>
            </Helmet>

            <div className='row'>
                <div className='small-title'>
                    Accretion Database For <strong>Enterprise</strong> 
                </div>
            </div>

            <TabComponent />

        </div>
    )
}