import Chatbot from 'react-chatbot-kit';  

import createConfig from './Config.js';
import MessageParser from './MessageParser.js';
import ActionProvider from './ActionProvider.js';

import './TitleChatComponent.css';

const TitleChatComponent = ({ propertyID, address }) => {    
    const config = createConfig(address);

    return (
        <div id="chat-bot">
        <Chatbot 
            config={config}
            messageParser={MessageParser}
            actionProvider={(props) => <ActionProvider {...props} propertyID={propertyID} />}
        />
        </div>
    );
};

export default TitleChatComponent;