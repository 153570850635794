import LoadingContent from '../animation/LoadingContent';
import '../TitleReport.css';

export default function ScheduleB ({ scheduleB }) {
    
    if (!scheduleB) {
        return (
            <div className='schedule-b'>            
                <div className='row'> <LoadingContent/>  </div>            
                <div className='row'> <LoadingContent/>  </div>
            </div>
        )
        
    }
    return (
        <div className='schedule-b'>
            <div className='row'> 
                <div className='col-val'>
                    {scheduleB}  
                </div>
            </div>                        
        </div>
    )
}
