// GENERATE TITLE REPORT BUTTON 
import { Link } from "react-router-dom";
import { AiFillMessage } from "react-icons/ai";
import { Icon } from "@chakra-ui/react";
import { Button } from "react-bootstrap";

import "../DatabaseDemo.css"

export default function TitleChat ( { propertyID, addressInfo } ) {    
    const address = `${addressInfo.street_number} ${addressInfo.route} ${addressInfo.locality} ${addressInfo.state} ${addressInfo.zipcode}`
    // parse the addressInfo propertyID to /title-report using query string 
    const queryParams = new URLSearchParams({
        propertyid: propertyID,
        address: address,
    });

    return (
        <div className="column" > 
            <Link to={`/title-chat/?${queryParams.toString()}`}>
                <Button                
                    variant='outline-primary' 
                    id='button-share'
                >                    
                    <Icon as={AiFillMessage}/>
                    Talk With This Title
                </Button>
            </Link>
        </div>
    )
}