import React, { useState, useEffect, } from 'react';

import VisualizationCard_v3 from '../database-demo/visualization-card/VisualizationCard_v3';

import { CustomOverlay } from '../components/overlays/Overlays'; 
import { ButtonRecomm } from '../components/buttons/Buttons';

import './Recommendation.css';
import '../overlay/overlay.css';

const urlBackendRecommendation= process.env.REACT_APP_BACKEND_DOMAIN + "/api/recommendation/"; 

const Recommendation = () => {
    const [properties, setProperties] = useState([]);
    const [error, setError] = useState(null);
    const [selectedProperty, setSelectedProperty] = useState(null); 
    // open Custom Overlay 
    const [isOpen, setIsOpen] = useState(false);   

    useEffect(() => {
        const fetchProperties = async () => {
            try {
                const response = await fetch(urlBackendRecommendation);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();                
                setProperties(data);
            } catch (error) {
                setError(error.message);
                console.error('Error fetching random properties:', error);
            }
        };

        fetchProperties();
    }, []);

    const handleVisualization = (property) => {
        setSelectedProperty(property);        
        setIsOpen(true);
    };

    return (
        <div className='recomm'>
            <div className='row'>               
                <h3>Recommendations</h3>
            </div>
            <div className='row'>
                {error && <p>Error: {error}</p>}
            </div>
            <div className='properties'>
                {properties.map(property => (
                    <div className='property' key={property.propertyID} >
                        <ButtonRecomm 
                            addressInfo={property.addressInfo} 
                            onClick={() => handleVisualization(property)}                             
                        />                        
                    </div>
                ))}
            </div>           
           <CustomOverlay isOpen={isOpen} onClose={() => setIsOpen(false)}>
                {selectedProperty && (
                    <VisualizationCard_v3
                        dataJSON={selectedProperty}
                    />
                )}
            </CustomOverlay>
        </div>
    );
};

export default Recommendation;