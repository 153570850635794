import React, { useEffect, useState, useRef } from 'react';
import { useAuth } from '../user-auth/AuthContext'; 

import { ButtonRecomm } from '../components/buttons/Buttons';
import { CustomOverlay } from '../components/overlays/Overlays';

import VisualizationCard_v3 from '../database-demo/visualization-card/VisualizationCard_v3';

import Loading from '../database-demo/loading-error-handling/Loading';
import Error from '../database-demo/loading-error-handling/Error';

import '../overlay/overlay.css';
import '../recommendation/Recommendation.css';

const urlBackendGetProperties = process.env.REACT_APP_BACKEND_DOMAIN + "/api/user-data/get-properties/";

export default function GetSavedProperties() {
    const { isLoggedIn } = useAuth();
    const [properties, setProperties] = useState([]);
    const [chats, setChats] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);    
    const [selectedProperty, setSelectedProperty] = useState(null);                

    // open Custom Overlay 
    const [isOpen, setIsOpen] = useState(false);           

    useEffect(() => {
        if (isLoggedIn) {
            const fetchProperties = async () => {
                try {
                    const response = await fetch(urlBackendGetProperties, {
                        method: 'GET',
                        credentials: 'include', // Ensure cookies are sent with the request
                    });

                    if (response.ok) {
                        const data = await response.json();
                        // console.log(data);
                        setProperties(data.saved_properties); 
                        setChats(data.chatted_properties); 
                    } else {
                        const errorData = await response.json();
                        setError(errorData.error || "An error occurred while fetching properties.");
                    }
                } catch (error) {
                    setError("An error occurred while fetching properties.");
                } finally {
                    setLoading(false);
                }
            };

            fetchProperties();
        } else {
            setProperties([]); // Clear properties if not logged in
        }
    }, [isLoggedIn]);

    const handleVisualization = (property) => {                        
        setSelectedProperty(property);        
        setIsOpen(true);
    }

    if (loading) {
        return (
            <div className='row'>
                <p>Loading Content For You...</p>                
                <Loading/>
            </div>            
        );
    } 
    if (error) {
        return (
            <div className='row'>
                <Error/>
            </div>  
        )
    } 

    return (
        <div className='recomm'>
            <div className='row' style={{marginTop:"4svh"}}>
                <h3>Recent Title Chats</h3>
            </div>
            {chats.length > 0 ? ( // New: Display recent title chats
                <div className='properties'>
                    {chats.map(property => (
                        <div className='property' key={property.propertyID} >
                            <ButtonRecomm 
                                addressInfo={property.addressInfo} 
                                onClick={() => handleVisualization(property)}                             
                            />                        
                        </div>
                    ))}                                        
                </div>
            ) : (
                <p>No recent title chats.</p>
            )}
            <div className='row' style={{marginTop:"4svh"}}>
                <h3>Saved Properties</h3>
            </div>
            {properties.length > 0 ? (
                <div className='properties'>
                    {properties.map(property => (
                        <div className='property' key={property.propertyID} >
                            <ButtonRecomm 
                                addressInfo={property.addressInfo} 
                                onClick={() => handleVisualization(property)}
                            /> 
                        </div>
                    ))}                     
                </div>
            ) : (
                <p>No saved properties.</p>
            )}            
            <CustomOverlay isOpen={isOpen} onClose={() => setIsOpen(false)}>
                {selectedProperty && (
                    <VisualizationCard_v3
                        dataJSON={selectedProperty}
                    />
                )}
            </CustomOverlay>
        </div>
    );
}