import React, {} from 'react';
import ReactMarkdown from 'react-markdown';

import { createChatBotMessage } from 'react-chatbot-kit';



import '../TitleChat.css'; 

const CustomMessage = ({ message }) => {
  return (    
    <div className="react-chatbot-kit-chat-bot-message">
      <ReactMarkdown>{message}</ReactMarkdown>
    </div>
  );
};



// Function to create a chat bot configuration with dynamic address
const createConfig = (address) => {
  const botName = "Accretion Intelligence";
  const greeting = `Hi there, how can I assist you with title work regarding the property at ${address}? .
                    \n I can help you with tasks like:                     
                    \n - Give me a detailed summary of the mortgage history. 
                    \n - Summarize any liens against this property. 
                    \n - Provide me a detailed summary of discharges for this property.  
                    \n - Check if there are foreclosures on this property. 
                    \n - What are existing easements on this property?  
                    \n - Compare all legal descriptions in all documents. 
    `;

  return {
    botName: botName,
    initialMessages: [createChatBotMessage(greeting)],
    customStyles: {      
      chatButton: {
        backgroundColor: "rgb(0, 0, 0, 0.7)",
      },
    },

    customComponents: {
      botChatMessage: (props) => <CustomMessage {...props} />,      
    },
    
  };
};

export default createConfig;