// GENERATE TITLE REPORT BUTTON 
import { Link } from "react-router-dom";
import { MdEditDocument } from "react-icons/md";
import { Icon } from "@chakra-ui/react";
import { Button } from "react-bootstrap";
import "../DatabaseDemo.css"

export default function GenerateTitleReport ( {addressInfo, propertyID} ) {    
    // parse the addressInfo propertyID to /title-report using query string 
    const queryParams = new URLSearchParams({
        propertyid: propertyID,
        addressinfo: JSON.stringify(addressInfo),        
    });

    return (
        <div className="column" > 
            <Link to={`/title-report/?${queryParams.toString()}`}>
                <Button                
                    variant='outline-primary' 
                    id='button-share'
                >
                    <Icon as={MdEditDocument}/>
                    Generate Title Report
                </Button>
            </Link>
        </div>
    )
}