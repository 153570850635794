import LoadingContent from '../animation/LoadingContent';
import ReactMarkdown from 'react-markdown';

import '../TitleReport.css';

export default function LegalDescription ({legalDescription}) {

    if (!legalDescription){
        return (
            <LoadingContent />
        )
    }

    const components = {
        p: ({node, ...props}) => <p style={{fontFamily: 'monospace'}} {...props} />
    }
    return (
        <div className='legal-description'>            
            <div className='row'>                                                     
                <ReactMarkdown components={components}>
                    {legalDescription}
                </ReactMarkdown>
            </div> 
        </div>
    )
}
