import imageTitleChat from "./title-chat.png";
import imageTitleReport from "./title-report.png";
import imageFineTuning from './fine-tuning.png';
import imageExistingHomeSale from './‎chart-existing-home-sale.png';
import imageLLMRAG from './llm-rag.png';
import imageVisualizationCard from './visualization-card.png';
import AnimationTypingAIForIndividual from "./AnimationTypingAIForIndividual";

import { ButtonTalkWithOurTeam, ButtonTryAccretionAIToday } from "../components/buttons/Buttons";

import "./Ai.css";

export default function Ai () {            

    return (
        <div className="ai">
            
            <div className="row" style={{marginTop:"3svh"}}>
                <div id="small-title">
                    Accretion AI For <strong>Enterprise</strong>
                </div>
            </div>            

            <div className="segment">
                <div className="row">
                    <div id="title">
                        A new way of title work in the age of AI 
                    </div>                
                </div> 
                <div className="row">
                    <div id="text">
                        Use natural language to get title work done. 
                        Abstract real estate titles by simply talking to documents. 
                    </div>
                </div>
                <div className="row" id="image">                    
                    <img src={imageTitleChat} alt="title chat"/>
                </div>
            </div>

            <div className="segment">
                <div className="row">
                    <div id="title">
                        Creating the most thorough examined title report
                    </div>                    
                </div>
                <div className="row">
                    <div id="text">
                        Real estate, the world's largest asset class, demands meticulous due diligence. 
                        Accretion is using AI to create the most thoroughly examined title reports with the click of a button. 
                    </div>
                </div>
                <div className="row" id="image">
                    <img src={imageTitleReport} alt="title-report"/>
                </div>
            </div>

            <div className="segment">
                <div className="row">
                    <div id="title">
                        Transforming traditional documents to be AI ready
                    </div>            
                </div>
                <div className="row">
                    <div id="text">
                        Turn your documents into a source of knowledge. 
                        Accretion helps you utilize your knowledge base to create the best AI for your enterprise. 
                    </div>
                </div>
                <div className="row" id="image">
                    <img src={imageLLMRAG} style={{maxWidth:"400px"}} alt="LLM with RAG"/>
                </div>
                <div className="row" style={{maxWidth:"500px"}}>
                    <div className="column">                    
                        <ButtonTalkWithOurTeam />
                    </div>
                    <div className="column">
                        <ButtonTryAccretionAIToday/>
                    </div>
                </div>
            </div>

            <div className="segment">
                <div className="row">
                    <div id="title">
                        Scale workload to meet the cyclical market
                    </div>
                </div>
                <div className="row">
                    <div id="text">
                        Stay in sync with the cyclical real estate market, 
                        elastically spinning up or scaling down AI agents for your title workload. 
                    </div>
                </div>
                <div className="row" id="image">
                    <img src={imageExistingHomeSale} alt="home sale chart"/>
                </div>
            </div>

            <div className="segment">
                <div className="row">
                    <div id="title">
                        Tailoring AI models for your market
                    </div>
                </div>
                <div className="row"> 
                    <div id="text">
                    Deploy AI agents as the most experienced title abstractors in your specific market. 
                    Equivalent to years of training your workforce, 
                    Accretion's AI team helps you tailor AI models for your specific needs, 
                    adhering to your region's title guidelines.
                    </div>
                </div>
                <div className="row" id="image">
                    <img src={imageFineTuning} alt="fine tuning"/>
                </div>
            </div>

            {/* INDIVIDUAL INVESTORS */}
            <div className="row" style={{marginTop: "5svh"}}>
                <div id="small-title">
                    Accretion AI for <strong>Individual Investors</strong>
                </div>
            </div>

            <div className="segment">
                <div className="row">
                    <div id="title">
                        Be the expert of your own property
                    </div>                
                </div>
                <div className="row">
                    <div id="text">
                        Unlock endless possibilities through simple chats. 
                        Accretion AI, powered by the Accretion Database, gives you the insights you need to make informed decisions about the properties you own or are considering.
                    </div> 
                </div>
                <div className="row" id="image">
                    <img src={imageVisualizationCard} alt="title visualization"/>
                </div>
                <div className="row" style={{maxWidth:"500px", backgroundColor:"rgb(0,0,0,0.1)", borderRadius:"25px", padding:"5px, 15px, 5px, 15px", marginTop:"10px", marginBottom:"20px"}}>                    
                    <AnimationTypingAIForIndividual/>                    
                </div> 
                <div className="row">
                    <div id="text">
                        Accretion AI distills knowledge from real estate documents to answer any questions you have.                        
                    </div>                    
                </div>
                <div className="row" style={{maxWidth:"500px"}}>
                    <div className="column">                    
                        <ButtonTalkWithOurTeam />
                    </div>
                    <div className="column">
                        <ButtonTryAccretionAIToday/>
                    </div>
                </div>
                               
            </div>
        </div>
    )
}