
import { useAuth } from './AuthContext'; 
import { useGoogleLogin } from '@react-oauth/google';
import Logout from './Logout';
import You from '../you/You.js';

import { Button } from 'react-bootstrap';
import { FcGoogle } from "react-icons/fc";
import { Icon } from '@chakra-ui/react';

import './user-auth.css';

const Login = () => {    
    
    const { authGoogle, isLoggedIn, user } = useAuth(); 

    const loginGoogle = useGoogleLogin({
        onSuccess: (response) => {
            // Handle successful authentication            
            const access_token = response.access_token; // Extract the token from response            
            const provider = "google";            
            // Verify token at the backend
            authGoogle(access_token, provider);            
        },
        onFailure: (error) => {
            // Handle errors
            console.error('Error:', error);
        }
    });

    if (isLoggedIn) {
        return (
            <You/>
        );
    }
  
    return (        
        <div className='login-container'>
            <div className="row">                
                <div className='title'>
                    Join Accretion 
                </div>
            </div>            
            <div className='row'>            
                <Button 
                    onClick={() => loginGoogle()}
                    variant="outline-primary" 
                    id="button-share" 
                    style={{background:"none"}}
                >
                    <Icon as={FcGoogle} style={{marginRight:"20px"}}/>
                    Continue with Google 
                </Button>                
            </div>            
        </div>        
    );
  };
  
export default Login;
