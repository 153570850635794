import { FiPlusCircle } from "react-icons/fi";
import { Icon } from "@chakra-ui/react";
import { useState } from "react";
import { Button } from "react-bootstrap";
import "../DatabaseDemo.css"

const urlBackendSaveProperty = process.env.REACT_APP_BACKEND_DOMAIN + "/api/user-data/save-property/";
// const urlBackendGetProperties = process.env.REACT_APP_BACKEND_DOMAIN + "/api/user-data/get-properties/";

export default function Save ({ propertyID }) {
    const [isSaving, setIsSaving] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);    

    const handleSave = async () => {
        setIsSaving(true);
        setError(null);
        setSuccess(null);        

        try {
            // Get CSRF token from cookies
            const csrftoken = document.cookie.split('; ').find(row => row.startsWith('csrftoken=')).split('=')[1];
        
            const response = await fetch(urlBackendSaveProperty, {
                method: 'POST',
                credentials: 'include', // Ensure cookies are sent with the request 
                headers: {
                    'Content-Type': 'application/json',      
                    'X-CSRFToken': csrftoken,               
                },
                body: JSON.stringify({ "propertyID": propertyID })
            });

            if (response.ok) {
                const result = await response.json();
                console.log(result);
                setSuccess("Property saved successfully!");
            } else {
                const errorData = await response.json();
                setError(errorData.error || "An error occurred while saving the property.");
            }
        } catch (error) {
            setError("An error occurred while saving the property.");
        } finally {
            setIsSaving(false);
        }
    };

    return (
        <div className="column" > 
            <Button 
                variant="outline-primary" 
                onClick={handleSave}
                id="button-share"
            >   
                <Icon as={FiPlusCircle}/>
                Save
            </Button>
        </div>
    )
}