import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { useAuth } from "../user-auth/AuthContext.js";

import LoginOverlay from "../user-auth/login/LoginOverlay.js";
import Loading from "../database-demo/loading-error-handling/Loading.js";

import PropertyInfo from './property-information/PropertyInfo.js';
import ScheduleA from './schedule-a/ScheduleA.js';
import LegalDescription from './legal-description/LegalDescription.js';
import ScheduleB from './schedule-b/ScheduleB.js';

import './TitleReport.css';

const url_generate_title_report = process.env.REACT_APP_BACKEND_DOMAIN + "/api/title-RAG/title-report/";

export default function TitleReport () {

    const { isLoggedIn } = useAuth(); 

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);            
    const propertyID = queryParams.get('propertyid'); 

    const [dataTitle, setDataTitle] = useState(); 
    
    const [propertyInfo, setPropertyInfo] = useState(null);
    const [scheduleA, setScheduleA] = useState(null);
    const [scheduleB, setScheduleB] = useState(null);
    const [legalDescription, setLegalDescription] = useState(null);
    const [clientInfo, setClientInfo] = useState(null);

    
        
    const fetchReportData = async (event) => { // API calls to backend to generate title report                        
        if (event) {
            event.preventDefault();
        }
        // API call to backend 
        const queryString = `?propertyID=${propertyID}`; 
        console.log("TitleReport: make API call to AccretionDB...");

        try {
            const response = await fetch(
                url_generate_title_report + queryString, 
                {
                    method: "GET",
                    credentials: "include",
                }
            )            
            if (response.ok) {
                const data = await response.json();                
                setDataTitle(data);                
                setPropertyInfo(data.property_info); 
                setScheduleA(data.schedule_a);       
                setScheduleB(data.schedule_b);       
                setLegalDescription(data.legal_description); 
                setClientInfo(data.client_info);
            } else {
                console.error("Failed to fetch data: ", response.statusText);
            }
        }
        catch (error) {
            console.error("TitleReport: error during fetch:", error)
        }
        console.log("TitleReport: Complete API call to AccretionDB")
    }; 

    useEffect(() => {        
        fetchReportData();
    }, [isLoggedIn]);    

    return (        
        <div className="title-report">
            {(!isLoggedIn) && (
                <LoginOverlay/>
            )}            
            <div className="row">
                <div id="title">Title Report (Beta)</div>                
            </div>
            <div style={{border:"solid black", borderRadius:"10px", paddingBottom:"2svh", paddingTop:"2svh", marginTop:"2svh"}}>
            { (propertyInfo && scheduleA && scheduleB && legalDescription && clientInfo) ? (
                <div> 
                    <div className="row">
                        <div id="small-title">Property Information</div>
                            <PropertyInfo propertyInfo={propertyInfo} clientInfo={clientInfo}/>
                        </div>
                    <div className="row">
                        <div id="small-title">Schedule A</div>
                        <ScheduleA scheduleA={scheduleA}/>
                    </div>
                    <div className="row">
                        <div id="small-title">Legal Description</div>
                        <LegalDescription legalDescription={legalDescription}/>
                    </div>
                    <div className="row">
                        <div id="small-title">Schedule B</div>
                        <ScheduleB scheduleB={scheduleB} />
                    </div>
                    {/* <div className="row">
                        <div id="small-title">Notes and Requirements</div>
                    </div>
                    <div className="row">
                        <div id="small-title">Title Policies</div>
                    </div>
                    <div className="row">
                        <div id="small-title">Accessor's Map</div>
                    </div>
                    <div className="row">
                        <div id="small-title">Privacy Notice</div>
                    </div>       */}
                </div>
                
            ) : (
                <Loading/>
            )}
            </div>      
        </div>
    )
}