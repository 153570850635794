import React from 'react';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,  
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Text,    
  HStack,
  VStack,
} from '@chakra-ui/react';

import { Icon, Divider } from '@chakra-ui/react';
import { FaCheck } from "react-icons/fa6";

import { ButtonTalkWithOurTeam, ButtonStartNow } from '../../components/buttons/ButtonsChakra';

import '../Database.css';

import imageTechSpec from './image-tech-spec.png';

const buttonStyleLink = {
    variant: "link",
    color: "black", 
}

const PricingFree = () => {    
    const benefits = [
        "Limited access to the Accretion database. ",
        "Access to Accretion AI that is best for title work. ",
        "Limited access to the Accretion beta products, including features like generating a title report.",
        "Get started now or book your free demo call with the Accretion team today. ", 
    ];

    return (    
        <VStack spacing={0} >
        {benefits.map((benefit, index) => (
            <HStack key={index} align="flex-start" spacing={3}  w="100%">
            <Icon as={FaCheck} boxSize={4} margin="5px 0 0 0"/>
            <Text fontSize="15px" textAlign="left" >{benefit}</Text>
            </HStack>
        ))}
        <HStack marginTop="40px">
            <ButtonStartNow />
        </HStack>
        </VStack>
        
    );
}

const PricingConsult = () => {
    const benefits = [
        "Accretion will provide top database/AI engineers onsite to work closely with your company for one week.",
        "During the week, we will assess your company AI needs to enhance efficiency. ",         
        "We will develop a full-stack action plan to enhance efficiency, from creating a database solution to making your data AI-ready, to fine-tuning the Accretion AI model tailored specifically for your enterprise.",         
    ];

    return (    
        <VStack spacing={0} >
        {benefits.map((benefit, index) => (
            <HStack key={index} align="flex-start" spacing={3}  w="100%">
            <Icon as={FaCheck} boxSize={4} margin="5px 0 0 0"/>
            <Text fontSize="15px" textAlign="left" >{benefit}</Text>            
            </HStack>
        ))}
        <Text fontSize="15px" textAlign="left" padding="10px"> 
            <strong>$5,000, </strong> 
            which can be used for our full-stack service computing credits. 
        </Text>
        </VStack>    
    );
}

const PricingFullStack = () => {
    const benefits = [
        "Full access to the latest Accretion AI models and database storage, to enhance your enterprise efficiency. ",         
        "Following the consultation, Accretion will build the best AI automation tools tailored exclusively for your company. ",
        "Upgrade to Accretion’s AI-ready database. ", 
        "Fine tune the Accretion AI models to meet your company’s and market’s needs. "
    ];

    return (    
        <VStack spacing={0} >
        {benefits.map((benefit, index) => (
            <HStack key={index} align="flex-start" spacing={3}  w="100%">
            <Icon as={FaCheck} boxSize={4} margin="5px 0 0 0"/>
            <Text fontSize="15px" textAlign="left" >{benefit}</Text>
            </HStack>
        ))}        
        
        <Text fontSize="15px" textAlign="left" padding="10px"> 
            <strong>$100 per month</strong> for full access to the standard solution. 
            Contact us for a customized solution.
        </Text> 

        </VStack>    
    );
}


const LearnMore = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>      
      <Button onClick={onOpen} {...buttonStyleLink}>
        Learn More
      </Button>
      
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent maxW="800px" minW="80%">
            <ModalCloseButton />

            <ModalHeader marginTop="10svh">Tech Spec</ModalHeader>
                        
            <ModalBody>                
                <div className='row' style={{maxWidth:"800px", fontSize:"18px", fontWeight:"bold", padding:"20px"}}>
                    Bring artificial intelligence to your company database using Accretion’s standard implementation of a vector database for Retrieval-Augmented Generation (RAG).
                </div>
                <img src={imageTechSpec} alt='image-tech-spec'/>
            </ModalBody>

            <Divider/>

            <ModalHeader>Pricing</ModalHeader>
            <ModalBody>
            <div className='row' id='pricing'>
                <div className='column' id='pricing-column'>
                    <div id='pricing-texts'>
                        <div className='row' id='pricing-heading'>
                            <p>Free </p>
                        </div>       
                        <PricingFree/> 
                    </div>

                    <div className='row' id='pricing-term'> 
                        <ButtonTalkWithOurTeam/>
                    </div>

                </div>
                <div className='column' id='pricing-column'>
                    <div id='pricing-texts'>
                        <div className='row' id='pricing-heading'>
                            <p>Consultation </p>
                        </div>
                        <PricingConsult/>
                    </div>
                    <div className='row' id='pricing-term'>   
                        <ButtonTalkWithOurTeam/>
                    </div>
                </div>
                
                <div className='column' id='pricing-column'>
                    <div id='pricing-texts'>
                        <div className='row' id='pricing-heading'>
                            <p> Full-Stack Service</p>
                        </div>    
                        <PricingFullStack/>
                    </div>
                    <div className='row' id='pricing-term'>
                        <ButtonTalkWithOurTeam/>
                    </div>
                </div>

            </div>
            </ModalBody>

        </ModalContent>
      </Modal>
    </>
  );
};

export default LearnMore;