import React from 'react';
import { Modal, ModalOverlay, ModalContent, ModalBody, ModalCloseButton } from '@chakra-ui/react';

const CustomOverlay = ({ isOpen, onClose, children }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent w="90svw" maxW="var(--max-width)" maxH="90svh">
        <ModalCloseButton />
        <ModalBody marginTop="30px" marginBottom="30px" >
            {children}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export { CustomOverlay };