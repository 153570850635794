import { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Login from "../Login";
import { useAuth } from "../AuthContext";

import "../../overlay/overlay.css";

export default function LoginOverlay({ message }) {
    const { isLoggedIn } = useAuth();
    
    const overlayRef = useRef(null);
    // const history = useNavigate();    

    return (
        <div>
            {!isLoggedIn && (
                <div className="overlay-contact-us">
                    <div className="overlay-contact-us-content" ref={overlayRef}>
                        <div className="row" style={{marginTop:"10svh"}}>
                            <Login/>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}