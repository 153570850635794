import TitleChatComponent from "./react-chatbot-kit/TitleChatComponent";
import { useLocation } from "react-router-dom";
import { useState, useEffect } from 'react';
import { useAuth } from "../user-auth/AuthContext.js";
import Loading from "../database-demo/loading-error-handling/Loading.js";
import LoginOverlay from "../user-auth/login/LoginOverlay.js";

import "./TitleChat.css";

const url_accretionDB_check_embedding = process.env.REACT_APP_BACKEND_DOMAIN + "/api/process-embedding/check-embedding/";

const checkEmbeddingsAvailable = async (propertyID) => {
    try {
        const query_string = url_accretionDB_check_embedding + `?propertyID=${propertyID}`;        
        const response = await fetch(query_string, 
            {method: "GET"}
        );
        if (response.ok) {                        
            return true; 
        }
        throw new Error('Network response was not ok.');
    } catch (error) {
        console.error('Error checking embeddings:', error);
        return false;
    }
};

export default function TitleChat () {
    const { isLoggedIn } = useAuth(); 

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const propertyID = queryParams.get('propertyid'); 
    const address = queryParams.get('address');

    const [isEmbeddingsAvailable, setIsEmbeddingsAvailable] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchEmbeddingsStatus = async () => {
            const available = await checkEmbeddingsAvailable(propertyID);
            setIsEmbeddingsAvailable(available);
            setLoading(false);
        };

        fetchEmbeddingsStatus();
    }, [propertyID]);

    if (loading) {
        return(
            <div className="title-chat">
                <p>Loading documents from Accretion vector DB</p>
                <Loading/>
            </div>
        ) 
    }

    return (
        <div className="title-chat">
            {(!isLoggedIn) && (
                <LoginOverlay/>
            )}
            <div className="row">
                <div id="chat-window">
                    {/* <TitleChatComponent propertyID={propertyID} address={address}/> */}
                    <div id="chat-window">
                        {isEmbeddingsAvailable ? (
                            <TitleChatComponent propertyID={propertyID} address={address} />
                        ) : (
                            <div>Embeddings are not available yet. Please try again later.</div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}