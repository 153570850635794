import { useEffect } from 'react';
import * as d3 from 'd3';
import ConvertSVG2PNG from './ConvertSVG2PNG';


// Helper functions
// get address in one line
const getOneLineAddress = (addressInfo) => {
    const addressParts = [
      addressInfo.street_number,
      addressInfo.route,
      addressInfo.unit,
      addressInfo.locality,
      addressInfo.state,
      addressInfo.zipcode
    ];
  
    // Filter out any undefined or empty parts and join them with a space
    const addressString = addressParts.filter(part => part).join(' ');
  
    // Return the address in upper case
    return addressString.toUpperCase();
  };
// Function to get the timestamp with the lowest year
const getLowestFullYear = (data) => {
    const lowestYearData = data.reduce((min, obj) => {
        const currentYear = new Date(obj.timestamp).getFullYear();        
        const minYear = new Date(min.timestamp).getFullYear();
        return currentYear < minYear ? obj : min;
    }).timestamp; 

    return new Date(lowestYearData).getFullYear();
};
// Format grantor grantee names 
const formatGrantorGranteeNames = (data) => {
    const grantorNames = data.grantor.map(grantor => grantor.name).join(', ');
    const granteeNames = data.grantee.map(grantee => grantee.name).join(', ');

    return `grantor: ${grantorNames}\ngrantee: ${granteeNames}`;
};

const CreateTitleVisualPNG = ({dataJson, setDataPNG, addressInfo}) => {           
    
    const dataV2 = dataJson.data;
    const dataAddress = getOneLineAddress(addressInfo);  
    
    const height = 300; 
    const width = 600; //FIXED WIDTH

    const borderRadiusOff = 10;     

    const heightRect = 50;
    const heightTitle = 10; 
    const marginTopBottom = 10;

    const fillOpacityOff = 0.6;     
    
    const yearEnd = new Date().getFullYear();    
    // define the starting year 
    const yearStart = getLowestFullYear(dataV2);    
    // if yearStart is not define, start use even year space from yearEnd 
    if (!yearStart) {
        console.log("yearStart not defined ", yearStart); 
        yearStart = yearEnd - dataV2.length;
    }    
    
    const yearTotal = yearEnd - yearStart;     

    const gapDeed = 10;     
    const yTitleDeed = marginTopBottom + heightTitle; 
    const yDeed = yTitleDeed + marginTopBottom + heightTitle;
    const heightDeed = heightRect; 
    const colorDeed = "lightblue"; 
    
    const yTitleMortgage = yDeed + heightRect + marginTopBottom * 2;
    const yMortgage = yTitleMortgage + marginTopBottom + heightTitle; 
    const widthMortgage = 20;
    const colorMortgage = "rgba(198, 45, 205, 0.5)"; 
    
    const yTitleOthers = yMortgage + heightRect + marginTopBottom * 2; 
    const yOthers = yTitleOthers + marginTopBottom + heightTitle; 
    const widthOthers = 20; 
    const colorOthers = "lightgreen";     

    const widthScale = d3.scaleLinear()
                            .domain([0, yearTotal])
                            .range([0, width-widthMortgage]); 
    
    // Create a temporary container
    const tempContainer = document.createElement('div');
    useEffect(() => {      
         
         tempContainer.style.position = 'absolute';
         tempContainer.style.top = '-9999px';
         document.body.appendChild(tempContainer);
 
         // Create SVG element within the temporary container
         const svg = d3.select(tempContainer)  
                        .append('svg')
                        .attr('height', height) 
                        .attr('width', width);    
        
        // clear previous svg content
        svg.selectAll("*").remove();
        
        svg.append("rect") // append gray background                        
            .attr("height", height)
            .attr("width", width)
            .attr("fill", "rgba(0, 0, 0, 0.02)")
            .attr("rx", 10)
            .attr("ry", 10)
        
        
        // deed title
        svg.append("text")
            .attr("x", width / 2) // x-coordinate of the center of the rectangle
            .attr("y", yTitleDeed) // y-coordinate of the center of the rectangle
            .attr("text-anchor", "middle") // center-align the text horizontally
            .attr("dominant-baseline", "middle") // center the text vertically
            .style("text-color","lightblue")
            .text("deed"); // set the text content 
        // mortgage title
        svg.append("text")
            .attr("x", width / 2) // x-coordinate of the center of the rectangle
            .attr("y", yTitleMortgage) // y-coordinate of the center of the rectangle
            .attr("text-anchor", "middle") // center-align the text horizontally
            .attr("dominant-baseline", "middle") // center the text vertically
            .style("text-color","lightblue")
            .text("mortgage"); // set the text content 
        // others title
        svg.append("text")
            .attr("x", width / 2) // x-coordinate of the center of the rectangle
            .attr("y", yTitleOthers) // y-coordinate of the center of the rectangle
            .attr("text-anchor", "middle") // center-align the text horizontally
            .attr("dominant-baseline", "middle") // center the text vertically
            .style("text-color","lightblue")
            .text("others"); // set the text content                 
       

        // loop through ATTOM data         
        let lastDeed;
        for (let i=0; i < dataV2.length; i++) {
            // mortgage info             
            if (dataV2[i].type === "mortgage") {                                                                                                      
                let xMortgage = widthScale(
                    new Date(dataV2[i].timestamp).getFullYear() - yearStart 
                );                 

                const rectMortgage = svg.append("rect")
                    .attr("class", "link rect-normal")
                    .attr("x", xMortgage) 
                    .attr("y", yMortgage)
                    .attr("width", widthMortgage)
                    .attr("height", heightDeed)
                    .attr("fill", colorMortgage) 
                    .attr("rx", borderRadiusOff)
                    .attr("ry",borderRadiusOff)
                    .attr("fill-opacity", fillOpacityOff)             
                    .on("click", () => { // onClick the button to the link                 
                    })

            }
            // deed info
            else if (dataV2[i].type === "deed") {                                                                              
                let deedWidth;                
                if (lastDeed) {
                    deedWidth = widthScale(
                        lastDeed - new Date(dataV2[i].timestamp).getFullYear() 
                   ); 
                    lastDeed = new Date(dataV2[i].timestamp).getFullYear();
                } else {
                    deedWidth = widthScale(
                        yearEnd - new Date(dataV2[i].timestamp).getFullYear() 
                   ); 
                    lastDeed = new Date(dataV2[i].timestamp).getFullYear(); 
                };             
                deedWidth = deedWidth - width/200; // shrink the width to preserve a gap

                let xDeed;
                xDeed = widthScale(
                    new Date(dataV2[i].timestamp).getFullYear() - yearStart 
                );                 
                
                const rectDeed = svg
                    .append("rect")                    
                    .attr("class", "link rect-normal")
                    .attr("x", xDeed + width/200)
                    .attr("y", yDeed)                    
                    .attr("height", heightDeed)
                    .attr("width", deedWidth) // Transition to the final width
                    .attr("fill", colorDeed)
                    .attr("rx", borderRadiusOff)
                    .attr("ry", borderRadiusOff)                                                    
                    .attr("fill-opacity", fillOpacityOff); // Transition to the final opacity                                                                   
            }
            // other info 
            else {                
                const xOthers = widthScale(
                    new Date(dataV2[i].timestamp).getFullYear() - yearStart 
                );                    

                const rectOthers = svg.append("rect")
                    .attr("class", "link rect-normal")
                    .attr("x", xOthers) 
                    .attr("y", yOthers)
                    .attr("width", widthOthers)
                    .attr("height", heightDeed)
                    .attr("fill", colorOthers) 
                    .attr("rx", borderRadiusOff)
                    .attr("ry",borderRadiusOff)
                    .attr("fill-opacity", fillOpacityOff)         
                   
            }
        }
                        
        ConvertSVG2PNG(tempContainer.firstChild, setDataPNG, dataAddress);
        
    }, [dataJson]);
    
    return () => {
        document.body.removeChild(tempContainer);
    };
};

export default CreateTitleVisualPNG;