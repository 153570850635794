import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import { Modal, ModalOverlay, ModalContent, ModalBody, useDisclosure, ModalCloseButton } from "@chakra-ui/react";

import TitleChat from '../share-save-edit/TitleChat.js';

import '../DatabaseDemo.css';

// Helper functions
// get address in one line
const getOneLineAddress = (addressInfo) => {
    const addressParts = [
      addressInfo.street_number,
      addressInfo.route,
      addressInfo.unit,
      addressInfo.locality,
      addressInfo.state,
      addressInfo.zipcode
    ];
  
    // Filter out any undefined or empty parts and join them with a space
    const addressString = addressParts.filter(part => part).join(' ');
  
    // Return the address in upper case
    return addressString.toUpperCase();
  };
// Function to get the timestamp with the lowest year
const getLowestFullYear = (data) => {
    const lowestYearData = data.reduce((min, obj) => {
        const currentYear = new Date(obj.timestamp).getFullYear();        
        const minYear = new Date(min.timestamp).getFullYear();
        return currentYear < minYear ? obj : min;
    }).timestamp; 

    return new Date(lowestYearData).getFullYear();
};
// Format grantor grantee names 
const formatGrantorGranteeNames = (data) => {
    const grantorNames = data.grantor.map(grantor => grantor.name).join(', ');
    const granteeNames = data.grantee.map(grantee => grantee.name).join(', ');

    return `grantor: ${grantorNames}\ngrantee: ${granteeNames}`;
};


const CreateTitleVisualD3 = ({dataJson, addressInfo, propertyID}) => { 
    // show overlays using Chakra UI
    const { isOpen, onOpen, onClose } = useDisclosure();

    const [pdfUrl, setPdfUrl] = useState("");

    // Function to trigger the PDF overlay with the provided URL
    const showPDFOverlay_Chakra = (url) => {
        if (url) {
            setPdfUrl(url); // Set the URL for the PDF
            onOpen(); // Open the modal
        } else {
            console.error("showPDFOverlay_Chakra empty PDF URL");
        }
        
    };

    const dataV2 = dataJson.data;    
    const dataAddress = getOneLineAddress(addressInfo);    
    const svgRef = useRef(); // ref for the svg graph 
    const tooltipRef = useRef(null); // Ref for the tooltip 

    const offset_x_tooltip = -500;
    const offset_y_tooltip = -150;
    
    const height = 300; 
    const width = Math.min(window.outerWidth * 0.82, 600);    

    const borderRadiusOff = 10;     

    const heightRect = 50;
    const heightTitle = 10; 
    const marginTopBottom = 10;

    const fillOpacityOff = 0.6; 
    const fillOpacityOn = 1;         
    
    const yearEnd = new Date().getFullYear();    
    // define the starting year 
    const yearStart = getLowestFullYear(dataV2);    
    // if yearStart is not define, start use even year space from yearEnd 
    if (!yearStart) {
        console.log("yearStart not defined ", yearStart); 
        yearStart = yearEnd - dataV2.length;
    }
    
    const yearTotal = yearEnd - yearStart;     

    const yTitleDeed = marginTopBottom + heightTitle; 
    const yDeed = yTitleDeed + marginTopBottom + heightTitle;
    const heightDeed = heightRect; 
    const colorDeed = "lightblue"; 
    
    const yTitleMortgage = yDeed + heightRect + marginTopBottom * 2;
    const yMortgage = yTitleMortgage + marginTopBottom + heightTitle; 
    const widthMortgage = 20;
    const colorMortgage = "rgba(198, 45, 205, 0.5)"; 

    const yTitleOthers = yMortgage + heightRect + marginTopBottom * 2; 
    const yOthers = yTitleOthers + marginTopBottom + heightTitle; 
    const widthOthers = 20; 
    const colorOthers = "lightgreen";     

    const widthScale = d3.scaleLinear()
                            .domain([0, yearTotal])
                            .range([0, width-widthMortgage]); 
    

    useEffect(() => {        
        // create svg canvas for the deed record        
        const svg = d3.select(svgRef.current)
                        .attr("height", height) 
                        .attr("width", width) 
        
        // clear previous svg content
        svg.selectAll("*").remove();
        
        svg.append("rect") // append gray background                        
            .attr("height", height)
            .attr("width", width)
            .attr("fill", "rgba(0, 0, 0, 0.02)")
            .attr("rx", 10)
            .attr("ry", 10)             

        // create toolTips              
        const tooltip = d3.select(tooltipRef.current) // Select the tooltip
                            .style('position', 'absolute')
                            .style('background-color', 'white')
                            .style('border', '1px solid black')
                            .style('padding', '5px')
                            .style('border-radius', '5px')
                            .style('visibility', 'hidden') // Initially hide the tooltip
                            .text('');
        
        const showTooltip = (text) => {
            tooltip.style('visibility', 'visible') // Show the tooltip
                    .html(text.replace(/\n/g, '<br/>'))
                    .style("font-family", "monospace")
                    .style("font-size", "12pt")
                    .style("max-width", `${width}px`);
                    
        };
        
        const hideTooltip = () => {
            tooltip.style('visibility', 'hidden'); // Hide the tooltip
        };
        
        const showRectOn = (obj) => {
            obj
                .attr("stroke", "gray")
                .attr("stroke-width", 2)
                .attr("fill-opacity", fillOpacityOn);                
        }

        const showRectOff = (obj) => {
            obj                
                .attr("stroke-width", 0)
                .attr("fill-opacity", fillOpacityOff);                
        }
        
        // deed title
        svg.append("text")
            .attr("x", width / 2) // x-coordinate of the center of the rectangle
            .attr("y", yTitleDeed) // y-coordinate of the center of the rectangle
            .attr("text-anchor", "middle") // center-align the text horizontally
            .attr("dominant-baseline", "middle") // center the text vertically
            .style("text-color","lightblue")
            .text("deed"); // set the text content 
        // mortgage title
        svg.append("text")
            .attr("x", width / 2) // x-coordinate of the center of the rectangle
            .attr("y", yTitleMortgage) // y-coordinate of the center of the rectangle
            .attr("text-anchor", "middle") // center-align the text horizontally
            .attr("dominant-baseline", "middle") // center the text vertically
            .style("text-color","lightblue")
            .text("mortgage"); // set the text content 
        // others title
        svg.append("text")
            .attr("x", width / 2) // x-coordinate of the center of the rectangle
            .attr("y", yTitleOthers) // y-coordinate of the center of the rectangle
            .attr("text-anchor", "middle") // center-align the text horizontally
            .attr("dominant-baseline", "middle") // center the text vertically
            .style("text-color","lightblue")
            .text("others"); // set the text content                 
       

        // loop through v2 data         
        let lastDeed;
        for (let i=0; i < dataV2.length; i++) {
            // mortgage info             
            if (dataV2[i].type === "mortgage") {                                                                                                      
                let xMortgage = widthScale(
                    new Date(dataV2[i].timestamp).getFullYear() - yearStart 
                );
                
                const rectMortgage = svg.append("rect")
                    .attr("class", "link rect-normal")
                    .attr("x", xMortgage) 
                    .attr("y", yMortgage)
                    .attr("width", widthMortgage)
                    .attr("height", heightDeed)
                    .attr("fill", colorMortgage) 
                    .attr("rx", borderRadiusOff)
                    .attr("ry",borderRadiusOff)
                    .attr("fill-opacity", fillOpacityOff)             
                    .on("click", () => { 
                        showPDFOverlay_Chakra(dataV2[i].document_link_presigned);
                    })
                    .on('mouseover', () => {
                        showRectOn(rectMortgage);
                        showTooltip( // Show deed details on mouseover
                            "date: " + new Date(dataV2[i].timestamp).toISOString().slice(0, 10) + "\n" +                            
                            "amount: $" + (dataV2[i].details.consideration).toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD',
                                minimumFractionDigits: 0, // Specify minimum digits after decimal point
                              }) + "\n" + 
                            formatGrantorGranteeNames(dataV2[i].grantor_grantee)                             
                        )                
                    })
                    .on('mousemove', (event) => { // Adjust tooltip position based on mouse position
                        tooltip.style('top', (event.pageY + offset_y_tooltip) + 'px')
                            .style('left', (event.pageX + offset_x_tooltip) + 'px');
                    })
                    .on('mouseout', () => {// Hide tooltip on mouseout     
                        showRectOff(rectMortgage);
                        hideTooltip(); 
                        d3.select(this)
                            .attr("rx", borderRadiusOff)
                            .attr("ry", borderRadiusOff)
                    });     
            }
            // deed info
            else if (dataV2[i].type === "deed") {                                                                              
                let deedWidth;                
                if (lastDeed) {
                    deedWidth = widthScale(
                        lastDeed - new Date(dataV2[i].timestamp).getFullYear() 
                   ); 
                    lastDeed = new Date(dataV2[i].timestamp).getFullYear();
                } else {
                    deedWidth = widthScale(
                        yearEnd - new Date(dataV2[i].timestamp).getFullYear() 
                   ); 
                    lastDeed = new Date(dataV2[i].timestamp).getFullYear(); 
                };             
                deedWidth = deedWidth - width/200; // shrink the width to preserve a gap

                let xDeed;
                xDeed = widthScale(
                    new Date(dataV2[i].timestamp).getFullYear() - yearStart 
                );                 
                
                const rectDeed = svg
                        .append("rect")                    
                        .attr("class", "link rect-normal")
                        .attr("x", xDeed + width/200)
                        .attr("y", yDeed)                    
                        .attr("height", heightDeed)
                        .attr("width", deedWidth) // Transition to the final width
                        .attr("fill", colorDeed)
                        .attr("rx", borderRadiusOff)
                        .attr("ry", borderRadiusOff)                                                    
                        .attr("fill-opacity", fillOpacityOff); // Transition to the final opacity

                rectDeed.on("click", () => { 
                        showPDFOverlay_Chakra(dataV2[i].document_link_presigned);
                    })
                    .on('mouseover', () => {
                        showRectOn(rectDeed);                        
                        showTooltip( // Show deed details on mouseover
                            "date: " + new Date(dataV2[i].timestamp).toISOString().slice(0, 10) + "\n" +                            
                            "amount: $" + (dataV2[i].details.consideration).toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD',
                                minimumFractionDigits: 0, // Specify minimum digits after decimal point
                              }) + "\n" + 
                            formatGrantorGranteeNames(dataV2[i].grantor_grantee)
                        )             
                    })
                    .on('mousemove', (event) => { // Adjust tooltip position based on mouse position
                        tooltip.style('top', (event.pageY + offset_y_tooltip) + 'px')
                            .style('left', (event.pageX + offset_x_tooltip) + 'px');
                    })
                    .on('mouseout', () => {// Hide tooltip on mouseout     
                        showRectOff(rectDeed);
                        hideTooltip();
                    });                                                                      

            }
            // other info 
            else {                
                const xOthers = widthScale(
                    new Date(dataV2[i].timestamp).getFullYear() - yearStart 
                );                    

                const rectOthers = svg.append("rect")
                    .attr("class", "link rect-normal")
                    .attr("x", xOthers) 
                    .attr("y", yOthers)
                    .attr("width", widthOthers)
                    .attr("height", heightDeed)
                    .attr("fill", colorOthers) 
                    .attr("rx", borderRadiusOff)
                    .attr("ry",borderRadiusOff)
                    .attr("fill-opacity", fillOpacityOff)             
                    .on("click", () => { 
                        showPDFOverlay_Chakra(dataV2[i].document_link_presigned);
                    })
                    .on('mouseover', () => {
                        showRectOn(rectOthers);
                        showTooltip( // Show deed details on mouseover
                            "date: " + new Date(dataV2[i].timestamp).toISOString().slice(0, 10) + "\n" +  
                            "type: " + dataV2[i].type + "\n" + 
                            formatGrantorGranteeNames(dataV2[i].grantor_grantee)                            
                        )                
                    })
                    .on('mousemove', (event) => { // Adjust tooltip position based on mouse position
                        tooltip.style('top', (event.pageY + offset_y_tooltip) + 'px')
                            .style('left', (event.pageX + offset_x_tooltip) + 'px');
                    })
                    .on('mouseout', () => {// Hide tooltip on mouseout     
                        showRectOff(rectOthers);
                        hideTooltip();                             
                    });     
            }
        }

        // ConvertSVG2PNG(svgRef.current, setDataPNG, dataAddress);
        
    }, [dataJson]);

    return (
        <div>           
            <>
                <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} isCentered >
                    <ModalOverlay />
                    <ModalContent maxW="800px" h="90svh" w="90svw">
                    <ModalCloseButton />
                    <ModalBody w="100%" marginTop="40px">
                        <iframe 
                            src={pdfUrl} 
                            width="100%" 
                            height="100%" 
                            style={{ border: 'none', height: "90%" }}
                        />
                        <div className='row' style={{maxWidth:"250px", marginTop:"20px"}}>
                            <TitleChat addressInfo={addressInfo} propertyID={propertyID} />
                        </div>
                    </ModalBody>
                    </ModalContent>
                </Modal>
            </>
            <div> {dataAddress} </div> 
            <svg ref={svgRef} width={width} height={height} ></svg>                        
            <div ref={tooltipRef} style={{textAlign:"left"}}></div> {/* Render the tooltip */}            
        </div>
    );
};

export default CreateTitleVisualD3;

