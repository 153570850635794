import LoadingContent from '../animation/LoadingContent';
import '../TitleReport.css';

export default function PropertyInfo ({propertyInfo, clientInfo}) { 
    
    if (!propertyInfo) {
        return (
            <div className='property-info'>
                <div className='row'> 
                    <div className='col-key'>Order No:  </div>
                    <div className='col-val'><LoadingContent/></div>                
                </div>
                <div className='row'> 
                    <div className='col-key'>Title No:  </div>
                    <div className='col-val'><LoadingContent/></div>                
                </div>
                <div className='row'> 
                    <div className='col-key'>Buyer/Borrower Name:  </div>
                    <div className='col-val'><LoadingContent/></div>                
                </div>
                <div className='row'> 
                    <div className='col-key'>Seller Name:  </div>
                    <div className='col-val'><LoadingContent/></div>                
                </div>
                <div className='row'> 
                    <div className='col-key'>Property Address: </div>
                    <div className='col-val'><LoadingContent/></div>                
                </div>
                <div className='row'> 
                    <div className='col-key'>Title Date:  </div>
                    <div className='col-val'><LoadingContent/></div>                
                </div>
            </div>
        );
    }
    

    const address = propertyInfo.address;     
    const titleDate = propertyInfo.title_date;    
    const titleNo = clientInfo.order_number;
    const orderNo = titleNo; 
    const clientName = clientInfo.client_name;
    const curretOwner = propertyInfo.current_owner;

    return (
        <div className='property-info'>
            <div className='row'> 
                <div className='col-key'>Order No:  </div>
                <div className='col-val'> {orderNo}  </div>                
            </div>

            <div className='row'> 
                <div className='col-key'>Title No:  </div>
                <div className='col-val'> {titleNo}  </div>                
            </div>

            <div className='row'> 
                <div className='col-key'>Buyer/Borrower Name:  </div>
                <div className='col-val'>{clientName}</div>                
            </div>

            <div className='row'> 
                <div className='col-key'>Seller Name:  </div>
                <div className='col-val'>{curretOwner}  </div>                
            </div>

            <div className='row'> 
                <div className='col-key'>Property Address: </div>
                <div className='col-val'> {address} </div>                
            </div>

            <div className='row'> 
                <div className='col-key'>Title Date:  </div>
                <div className='col-val'>{titleDate} </div>                
            </div>
        </div>
    )
}
