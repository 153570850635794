import { TypeAnimation } from 'react-type-animation';
import { Box } from '@chakra-ui/react';

const animationMessages = {
  "APN": "Find mortgage documents for APN number 571-90085.",
  "Address": "Search for discharges related to the address at 100 Broadway.",
  "Name": "Find liens associated with the name John Doe.",
  "Title number": "Find legal descriptions for title number 189574.",  
  "Anything": "Ask your database anything",
};

const BoxStyle = {
  p:4, 
  borderRadius: "25px",
  border: "solid black", 
  bg:"gray.100",
  textAlign: "left",
  paddingLeft:"30px",
}

const AnimationTypingSearch= ({ searchTerm }) => {    

  return (    
    <Box {...BoxStyle}>
      <TypeAnimation 
        key={searchTerm}
        sequence={[
          animationMessages[searchTerm], // Type the animation text
          1000,          // Pause for 2 seconds after typing
      ]}
        wrapper="span"
        speed={90}
        deletionSpeed={90}
        style={{ fontSize: '18px', display: 'inline-block', textAlign: 'left' }}
        repeat={0}
      />    
    </Box>
  );
};

export default AnimationTypingSearch;