import { useAuth } from './AuthContext';
import { Button } from 'react-bootstrap';

const urlBackendAuth = process.env.REACT_APP_BACKEND_DOMAIN + "/api/auth/";

// logout button 
export default function Logout () {
    const { logout } = useAuth(); 

    const handleLogout = async () => {
        try {
            const response = await fetch(urlBackendAuth + 'logout/', {
                method: 'POST',
                credentials: 'include', // Ensure cookies are sent with the request
            });
            if (response.ok) {
                logout(); 
            } else {
                console.error('Logout failed:', response.statusText);
            }
        } catch (error) {
            console.error('Error during logout:', error);
        }
    };
    
    return (
        <div style={{maxWidth:"100px", marginTop:"10svh"}}>
            <Button 
                onClick={handleLogout}
                variant='outline-primary' 
                id='button-share'
                style={{background:"none"}}
            >
                Logout 
            </Button>
        </div>
    )
};