import { useAuth } from '../user-auth/AuthContext';
import Logout from '../user-auth/Logout'; 
import Login from '../user-auth/Login';
import GetSavedProperties from './GetSavedProperties'; 

import './You.css';

export default function You () {
    const { isLoggedIn } = useAuth(); 
    
    return (
        <div className='you'>
            {isLoggedIn ? 
                <div className='row'>
                    <div className='row' style={{minHeight:"60svh"}}>
                        <GetSavedProperties />                         
                    </div>
                    
                    <div className='row' style={{marginTop:"auto"}}>
                        <Logout/>
                    </div>
                </div>
                :
                <div className='row' >
                    <Login/>
                </div>
            }
        </div>
    );
}